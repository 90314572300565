// Auto generated file
// Do not change '//Generator' comments
import { RouteItem } from 'onka-react-admin-core';
import { route as sectorSearchRoute } from './pages/sectorSearch';
import { route as emotionSearchRoute } from './pages/emotionSearch';
import { route as reasonSearchRoute } from './pages/reasonSearch';
import { route as descriptionSearchRoute } from './pages/descriptionSearch';
import { route as primaryTextSearchRoute } from './pages/primaryTextSearch';
import { route as purposeSearchRoute } from './pages/purposeSearch';
import { route as campaignSearchRoute } from './pages/campaignSearch';
import { route as userSearchRoute } from './pages/userSearch';
import { route as imageSearchRoute } from './pages/imageSearch';
import { route as algorithmSearchRoute } from './pages/algorithmSearch';
import { route as adSearchRoute } from './pages/adSearch';
import { route as imageSearchInlineRoute } from './pages/imageSearchInline';
import { route as imageSourceSearchRoute } from './pages/imageSourceSearch';
import { route as imageSearchCropRoute } from './pages/imageSearchCrop';
import { route as userImageSearchRoute } from './pages/userImageSearch';
import { route as userSummarySearchRoute } from './pages/userSummarySearch';
import { route as imageSearchForLicenseRoute } from './pages/imageSearchForLicense';
import { route as imageSearchForContributerRoute } from './pages/imageSearchForContributer';
import { route as contributerSearchRoute } from './pages/contributerSearch';
import { route as imageSearchInlineCreatorHubRoute } from './pages/imageSearchInlineCreatorHub';
import { route as registrationPromotionSearchRoute } from './pages/registrationPromotionSearch';
import { route as registrationPromotionHistorySearchRoute } from './pages/registrationPromotionHistorySearch';
import { route as countrySearchRoute } from './pages/countrySearch';
import { route as stateProvinceSearchRoute } from './pages/stateProvinceSearch';
import { route as utmSearchRoute } from './pages/utmSearch';
//Generator1

const routes: RouteItem[] = [
    sectorSearchRoute,
    emotionSearchRoute,
    reasonSearchRoute,
    descriptionSearchRoute,
    primaryTextSearchRoute,
    purposeSearchRoute,
    campaignSearchRoute,
    userSearchRoute,
    imageSearchRoute,
    algorithmSearchRoute,
    adSearchRoute,
    imageSearchInlineRoute,
    imageSourceSearchRoute,
    imageSearchCropRoute,
    userImageSearchRoute,
    userSummarySearchRoute,
    imageSearchForLicenseRoute,
    imageSearchForContributerRoute,
contributerSearchRoute,
imageSearchInlineCreatorHubRoute,
registrationPromotionSearchRoute,
registrationPromotionHistorySearchRoute,
countrySearchRoute,
stateProvinceSearchRoute,
utmSearchRoute,
//Generator2
];

export default routes;