import adminApi from './adminApi';
import adzyApi from './adzyApi';
import emailApi from './emailApi';
import paymentApi from './paymentApi';
import commonApi from './commonApi';
import contributerApi from './contributerApi';
import { route as roleMap } from '../pages/adminUserRoleMap';
import { route as campaignTest } from '../pages/campaignTest';

export default [adminApi, adzyApi, emailApi, paymentApi, commonApi, [roleMap, campaignTest], contributerApi];
