// Auto generated file
import { globalEnums } from 'onka-react-admin-core';

const emailApiEnums = {
  ...globalEnums,
  ...{

    EmailStatus : {
            Pending: 1, // 
            Sent: 2, // 
            Failed: 3, // 
    },
  }
}
export default emailApiEnums