import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  allInputs,
  ApiBusinessLogic,
  DetailComponentProp,
  GridComponentProp,
  InputComponentProp,
  LibService,
  UIManager,
  makeStyles,
} from 'onka-react-admin-core';
import { Button, Grid } from '@mui/material';
import LaunchIcon from '@mui/icons-material/Launch';
import config from '../../../data/config';
import QueryBuilderComponent from './QueryBuilderComponent';
import { useDropzone } from 'react-dropzone';
import Chip from '@mui/material/Chip';
import ReactCrop, { makeAspectCrop, centerCrop, convertToPercentCrop, convertToPixelCrop } from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import CircularProgress from '@mui/material/CircularProgress';
import ImageIcon from '@mui/icons-material/Image';
import contributerApiEnums from '../modules/contributerApi/contributerApiEnums';

function ReadonlyImageComponent(props: InputComponentProp) {
  const [link, setLink] = useState('');
  const [img, setImg] = useState('');
  const formKey = props.formKey;
  const [version, setVersion] = useState(new Date().getTime());

  useEffect(() => {
    var subscription = props.form.subscribe(formKey, (data) => {
      const id = LibService.instance().getValue(data, 'id');
      const nameOriginal = LibService.instance().getValue(data, 'nameOriginal');

      setLink(config.CDN_URL + '/' + nameOriginal + '?v=' + version);
      setImg(config.CDN_URL + '/t/' + id + '.jpg?v=' + version);
    });
    return () => {
      subscription.unsubscribe();
    };
  }, []);

  if (!link) return <div />;
  return (
    <a href={link} target="_blank">
      <img src={img} style={{ maxWidth: 200, maxHeight: 200 }} alt={''} />
    </a>
  );
}
function GridImageComponent(props: GridComponentProp) {
  const [loading, setLoading] = useState(false);
  const [showBtn, setShowBtn] = useState(false);
  const [version, setVersion] = useState(new Date().getTime());
  const resizeImage = () => {
    setLoading(true);
    new ApiBusinessLogic()
      .request('POST', '/AdzyApi/ImageSearchCrop/resizeThumb', { id: props.rowData.id })
      .then((response) => {
        setShowBtn(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onError = () => {
    setShowBtn(true);
  };

  const field = props.gridField;
  var path = LibService.instance().getPath(field.prefix, field.name);
  var val = LibService.instance().getValue(props.rowData, path);
  // var link = config.CDN_URL + '/' + val;
  // if (props.rowData?.userId || props.rowData?.isUploadedByUser) {
  //   link = config.CDN_URL + '/r/' + props.rowData.id + '_100.jpg';
  // }
  var imageId = props.rowData.imageId || props.rowData.id;
  var link = config.CDN_URL + '/t/' + imageId + '.jpg?v=' + version;
  var originalLink = config.CDN_URL + '/' + props.rowData.nameOriginal;
  return (
    <>
      {!showBtn && (
        <a href={originalLink} target="_blank">
          <img src={link} style={{ maxWidth: 100, maxHeight: 150 }} alt={imageId + '.jpg'} title={props.rowData?.id} onError={onError} />
        </a>
      )}
      {showBtn && (
        <Button
          disabled={loading}
          size="small"
          variant="text"
          color="secondary"
          startIcon={loading ? <CircularProgress color="secondary" size="1em" /> : <ImageIcon />}
          onClick={resizeImage}
        >
          Generate
        </Button>
      )}
    </>
  );
}
function DetailImageComponent(props: DetailComponentProp) {
  const [version, setVersion] = useState(new Date().getTime());
  const field = props.field;
  var val = props.rowData;
  var link = config.CDN_URL + '/' + val;
  if (props.rowData?.userId || props.rowData?.isUploadedByUser) {
    link = config.CDN_URL + '/r/' + props.rowData.id + '_100.jpg?v=' + version;
  }
  return (
    <Grid container spacing={3}>
      <Grid item xs={4}>
        <strong>{LibService.instance().getFieldLabel(props.pageConfig, field.name)}</strong>
      </Grid>
      <Grid item xs={8}>
        :{' '}
        <a href={link} target="_blank">
          <img src={link} style={{ maxWidth: 100, maxHeight: 200 }} alt={val} title={props.rowData?.id} />
        </a>
      </Grid>
    </Grid>
  );
}
export const useStylesUpsertImageComponent = makeStyles()((theme) => ({
  dropZone: {
    border: '1px dashed ' + theme.palette.text.primary,
    padding: 10,
  },
  resizedDone: {
    backgroundColor: '#5fd45f',
    color: 'white',
  },
  notRevised: {
    backgroundColor: '#7b52cc',
    color: 'white',
  },
  imgFooter: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexWrap: 'wrap',
    minHeight: 40,
    padding: '0 5px',
  },
  a: {
    color: 'inherit',
    display: 'inline-flex',
    marginRight: 5,
  },
}));
function UpsertImageComponent(props: InputComponentProp) {
  const { classes } = useStylesUpsertImageComponent();
  const dataRef = useRef<any>({});
  const [version, setVersion] = useState(new Date().getTime());
  const [value, setValue] = useState('');
  const [files, setFiles] = useState<File[]>([]);
  const formKey = props.formKey;

  const path = LibService.instance().getPath(props.field.prefix, 'nameOriginal' /*props.field.name*/);

  useEffect(() => {
    var subscription = props.form.subscribe(formKey, (data) => {
      dataRef.current = data;
      const rowData = LibService.instance().getValue(data, path);
      if (value == rowData) return;
      setValue(rowData || '');
      if (!data?.isFileUpload) {
        props.form.handleChanges(formKey, [{ name: 'isFileUpload', value: true }]);
      }
    });
    return () => {
      subscription.unsubscribe();
    };
  }, []);

  const onDrop = useCallback((acceptedFiles) => {
    if (!acceptedFiles || acceptedFiles.length == 0) return;
    setFiles(acceptedFiles);
    props.form.handleChanges(formKey, [
      { name: 'isFileUpload', value: true },
      { name: 'files', value: acceptedFiles[0] },
      { name: 'width', value: acceptedFiles[0].naturalWidth },
      { name: 'height', value: acceptedFiles[0].naturalHeight },
    ]);
    setVersion(version + 1);
  }, []);

  const handleDelete = (file: File) => {
    setFiles(files.filter((x) => x.name != file.name));
    props.form.handleChanges(formKey, [
      { name: 'isFileUpload', value: false },
      { name: 'files', value: null },
    ]);
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop, maxFiles: 1 });

  const [displayCrop, setDisplayCrop] = useState(false);
  const imgRef = useRef<any>(null);
  const [cropImgUrl, setCropImgUrl] = useState('');
  const [crop, setCrop] = useState<any>({ unit: '%', width: 100, minWidth: 600, minHeight: 600, aspect: 1 }); // aspect: 16 / 9
  const [completedCrop, setCompletedCrop] = useState<any>(null);

  function completedCropDone(completedCropData) {
    setCompletedCrop(completedCropData);
    if (!imgRef.current) return;
    const image = imgRef.current;
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;

    props.form.handleChanges(formKey, [
      { name: 'x', value: Math.floor(completedCropData.x * scaleX) },
      { name: 'y', value: Math.floor(completedCropData.y * scaleY) },
      { name: 'width', value: Math.floor(completedCropData.width * scaleX) },
      { name: 'height', value: Math.floor(completedCropData.height * scaleY) },
    ]);
  }

  useEffect(() => {
    var imgUrl;
    if (files.length == 0 && dataRef.current['nameOriginal'] && dataRef.current['nameOriginal'] != '-') {
      imgUrl = config.CDN_URL + '/' + dataRef.current['nameOriginal'];
    } else if (files.length > 0) {
      imgUrl = URL.createObjectURL(files[0]);
    }
    setCropImgUrl(imgUrl);
  }, [files, value]);

  const onChange = useCallback((crop, percentCrop) => {
    setCrop(crop);
  }, []);

  const onLoad = useCallback((img) => {
    imgRef.current = img;
    if (!dataRef.current?.cropX) return;
    const image = imgRef.current;
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    setCrop({
      ...crop,
      unit: 'px',
      x: dataRef.current.cropX / scaleX,
      y: dataRef.current.cropY / scaleY,
      width: dataRef.current.cropWidth / scaleX,
      height: dataRef.current.cropHeight / scaleY,
    });
    return false;
  }, []);

  function openResizeDialog() {
    UIManager.instance().dialog.open(
      {
        width: '90vw',
        height: '90vh',
        title: 'Resize Image',
        url: window.location.origin + '/#/panel/AdzyApi/ImageSearchCrop',
        hideActions: true,
        defaultValues: { id: dataRef.current.id },
        hideFilters: true,
      },
      (res: any) => {
        window.location.reload();
      },
      {
        hasBackdrop: true,
        closable: true,
      }
    );
  }

  return (
    <div>
      <div {...getRootProps()} className={classes.dropZone}>
        <input {...getInputProps()} />
        {isDragActive ? <p>Drop the files here ...</p> : <p>Drag 'n' drop some files here, or click to select files</p>}
      </div>
      <div style={{ textAlign: 'center', padding: 10 }}>
        {files.map((x, i) => (
          <div key={i}>
            <div>
              <img src={URL.createObjectURL(x)} style={{ maxWidth: 200, maxHeight: 300 }} alt={x.name} />
            </div>
            <Chip label={x.name} onDelete={(e) => handleDelete(x)} />
          </div>
        ))}
        {files.length == 0 && value && dataRef.current.isResized && (
          <a href={config.CDN_URL + '/r/' + dataRef.current.hash + '_1080.jpg?v=' + version} target="_blank" style={{ width: '100%' }} title="Square 1080x1080">
            <img src={config.CDN_URL + '/r/' + dataRef.current.hash + '_1080.jpg?v=' + version} style={{ width: '100%' }} alt={'Square 1080x1080'} />
          </a>
        )}
        {files.length == 0 && value && (
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around' }}>
            <a href={config.CDN_URL + '/' + dataRef.current.nameOriginal + '?v=' + version} target="_blank" title="Original">
              <img src={config.CDN_URL + '/t/' + dataRef.current.id + '.jpg?v=' + version} style={{ maxWidth: 100, maxHeight: 200 }} alt="Original" />
            </a>
            {dataRef.current.isResized && (
              <>
                {dataRef.current.portrait && (
                  <a href={config.CDN_URL + '/r/' + dataRef.current.hash + '_1920x1080.jpg?v=' + version} target="_blank" title="Portrait 1920x1080">
                    <img
                      src={config.CDN_URL + '/r/' + dataRef.current.hash + '_1920x1080.jpg?v=' + version}
                      style={{ maxWidth: 100, maxHeight: 200 }}
                      alt={'Portrait 1920x1080'}
                    />
                  </a>
                )}
                {dataRef.current.landscape && (
                  <a href={config.CDN_URL + '/r/' + dataRef.current.hash + '_1080x1920.jpg?v=' + version} target="_blank" title="Landscape 1080x1920">
                    <img
                      src={config.CDN_URL + '/r/' + dataRef.current.hash + '_1080x1920.jpg?v=' + version}
                      style={{ maxWidth: 100, maxHeight: 200 }}
                      alt={'Landscape 1080x1920'}
                    />
                  </a>
                )}
              </>
            )}
          </div>
        )}
        <hr />
        {/* {!displayCrop && cropImgUrl && <Button onClick={(e) => setDisplayCrop(true)}>Crop Image</Button>} */}
        {props.isEdit && files.length == 0 && cropImgUrl && <Button onClick={openResizeDialog}>Open Resize</Button>}
        {displayCrop && cropImgUrl && (
          <Button onClick={(e) => setDisplayCrop(false)} color="secondary">
            Cancel Crop Image
          </Button>
        )}
        {displayCrop && cropImgUrl && (
          <ReactCrop crop={crop} onChange={onChange} onComplete={(c) => completedCropDone(c)}>
            <img src={cropImgUrl} onLoad={onLoad} style={{ height: 200 }} />
          </ReactCrop>
        )}
      </div>
    </div>
  );
}
function ImageInlineCropComponent(props: InputComponentProp) {
  const { classes } = useStylesUpsertImageComponent();
  const dataRef = useRef<any>({});
  const [refresh, setRefresh] = useState(0);
  const [version, setVersion] = useState(new Date().getTime());
  const [value, setValue] = useState('');
  const [files, setFiles] = useState<File[]>([]);
  const formKey = props.formKey;

  const path = LibService.instance().getPath(props.field.prefix, props.field.name);

  var cropLetter = '';
  var aspect = 1;
  if (props.field.name == 'portrait') {
    cropLetter = 'P';
    aspect = 9 / 16;
  } else if (props.field.name == 'landscape') {
    cropLetter = 'L';
    aspect = 16 / 9;
  }
  const cropXName = `crop${cropLetter}X`;
  const cropYName = `crop${cropLetter}Y`;
  const cropWidthName = `crop${cropLetter}Width`;
  const cropHeightName = `crop${cropLetter}Height`;

  const log = function (...params) {
    //if (cropLetter != 'L') return;
    //@ts-ignore
    //console.log.apply(console, params);
  };

  useEffect(() => {
    var subscription = props.form.subscribe(formKey, (data) => {
      dataRef.current = data;
      const rowData = LibService.instance().getValue(data, path);
      if (value != rowData) {
        setValue(rowData || '');
      }
      setRefresh((refresh) => refresh + 1);
    });
    return () => {
      subscription.unsubscribe();
    };
  }, []);

  const imgRef = useRef<any>(null);
  const [cropImgUrl, setCropImgUrl] = useState('');
  const [crop, setCrop] = useState<any>({ unit: '%', width: 100, minWidth: 100, minHeight: 100, aspect: aspect }); // aspect: 16 / 9
  const [completedCrop, setCompletedCrop] = useState<any>(null);

  const fixNumbers = (x) => {
    if (!x) return 0;
    return Math.abs(Math.floor(x));
  };

  function completedCropDone(completedCropData) {
    log('completedCropDone', completedCropData);
    setCompletedCrop(completedCropData);
    return;
    if (!imgRef.current) return;
    const image = imgRef.current;
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;

    props.form.handleChanges(formKey, [
      { name: cropXName, value: fixNumbers(completedCropData.x * scaleX) },
      { name: cropYName, value: fixNumbers(completedCropData.y * scaleY) },
      { name: cropWidthName, value: fixNumbers(completedCropData.width * scaleX) },
      { name: cropHeightName, value: fixNumbers(completedCropData.height * scaleY) },
      { name: 'width', value: image.naturalWidth },
      { name: 'height', value: image.naturalHeight },
    ]);
  }

  useEffect(() => {
    log('crop changed', crop.unit, crop);
    if (!imgRef.current) return;
    const image = imgRef.current;

    if (crop.unit == '%') {
      props.form.handleChanges(formKey, [
        { name: cropXName, value: fixNumbers((crop.x * image.naturalWidth) / 100) },
        { name: cropYName, value: fixNumbers((crop.y * image.naturalHeight) / 100) },
        { name: cropWidthName, value: fixNumbers((crop.width * image.naturalWidth) / 100) },
        { name: cropHeightName, value: fixNumbers((crop.height * image.naturalHeight) / 100) },
        { name: 'width', value: image.naturalWidth },
        { name: 'height', value: image.naturalHeight },
      ]);
      return;
    }

    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;

    log('crop changed2', image.naturalWidth, image.width, scaleX);
    log('crop changed2', image.naturalHeight, image.height, scaleY);

    props.form.handleChanges(formKey, [
      { name: cropXName, value: fixNumbers(crop.x * scaleX) },
      { name: cropYName, value: fixNumbers(crop.y * scaleY) },
      { name: cropWidthName, value: fixNumbers(crop.width * scaleX) },
      { name: cropHeightName, value: fixNumbers(crop.height * scaleY) },
      { name: 'width', value: image.naturalWidth },
      { name: 'height', value: image.naturalHeight },
    ]);
  }, [crop]);

  useEffect(() => {
    var imgUrl;
    if (files.length == 0 && dataRef.current['nameOriginal'] && dataRef.current['nameOriginal'] != '-') {
      imgUrl = config.CDN_URL + '/' + dataRef.current['nameOriginal'];
    } else if (files.length > 0) {
      imgUrl = URL.createObjectURL(files[0]);
    }
    setCropImgUrl(imgUrl);
  }, [files, value]);

  const onChange = useCallback((crop, percentCrop) => {
    log('onChange', formKey, crop, percentCrop);
    setCrop(crop);
  }, []);

  const onLoad = useCallback((e) => {
    var img = e.target;
    imgRef.current = img;
    log('onLoad', cropLetter, img);
    if (dataRef.current && !dataRef.current['width']) {
      props.form.handleChanges(formKey, [
        { name: 'width', value: img.naturalWidth },
        { name: 'height', value: img.naturalHeight },
      ]);
    }
    if (!dataRef.current || dataRef.current[cropXName] === undefined) {
      const aspect = crop.aspect;
      var ratio = img.width / img.height;

      const width = ratio < aspect ? 100 : (aspect / ratio) * 100;
      const height = ratio > aspect ? 100 : (ratio / aspect) * 100;
      const y = Math.max((100 - height) / 2, 0);
      const x = Math.max((100 - width) / 2, 0);

      setCrop({
        ...crop,
        unit: '%',
        width,
        height,
        x,
        y,
      });
      return false;
    }
    const image = imgRef.current;
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    log('onLoad scale', cropLetter, scaleX, scaleY);
    log('onLoad scale2', cropLetter, dataRef.current[cropHeightName], dataRef.current[cropHeightName] / scaleY, image.naturalHeight);
    setCrop({
      ...crop,
      unit: 'px',
      x: fixNumbers(dataRef.current[cropXName] / scaleX),
      y: fixNumbers(dataRef.current[cropYName] / scaleY),
      width: Math.min(fixNumbers(dataRef.current[cropWidthName] / scaleX), image.naturalWidth),
      height: Math.min(fixNumbers(dataRef.current[cropHeightName] / scaleY), image.naturalHeight),
    });
    return false;
  }, []);

  log('props', props);
  log('dataRef.current', dataRef.current);
  log('crop', crop);
  log('completedCrop', completedCrop);
  log('cropImgUrl', cropImgUrl);
  const handleChange = (e: any) => {
    props.form.handleChanges(props.formKey, [{ name: props.path, value: e.target.checked }]);
  };

  var className;
  if (!dataRef.current?.isRevised) {
    className = classes.notRevised;
  } else if (
    dataRef.current?.isResized &&
    (props.field.name == 'nameOriginal' ||
      (props.field.name == 'portrait' && dataRef.current.portrait) ||
      (props.field.name == 'landscape' && dataRef.current.landscape))
  ) {
    className = classes.resizedDone;
  }

  return (
    <div className={className} style={{ textAlign: 'center' }}>
      {/* {files.length == 0 && value && (
          <a href={config.CDN_URL + '/' + value} target="_blank">
            <img src={config.CDN_URL + '/' + value} style={{ width: 200, maxHeight: 300 }} alt={''} />
          </a>
        )} */}
      {/* {refresh}-{dataRef.current?.resizeDate} */}
      <ReactCrop crop={crop} onChange={onChange} onComplete={(c) => completedCropDone(c)} aspect={aspect} minWidth={100}>
        <img src={cropImgUrl + '?v=' + version} onLoad={onLoad} style={{ height: 200 }} />
      </ReactCrop>
      <div className={classes.imgFooter}>
        {dataRef.current && dataRef.current.isResized && (
          <>
            {props.field.name == 'nameOriginal' && (
              <>
                <a href={`${config.CDN_URL}/${dataRef.current.nameOriginal}?v=${version}`} target="_blank" className={classes.a}>
                  <LaunchIcon fontSize="small" /> Original
                </a>
                <a href={`${config.CDN_URL}/r/${dataRef.current.id}_428.jpg?v=${version}`} target="_blank" className={classes.a}>
                  <LaunchIcon fontSize="small" /> 428
                </a>
                <a href={`${config.CDN_URL}/r/${dataRef.current.hash}_1080.jpg?v=${version}`} target="_blank" className={classes.a}>
                  <LaunchIcon fontSize="small" /> 1080
                </a>
              </>
            )}
            {props.field.name == 'portrait' && (
              <>
                <a
                  href={`${config.CDN_URL}/r/${dataRef.current.hash}_1920x1080.jpg?v=${version}`}
                  target="_blank"
                  className={classes.a}
                >
                  <LaunchIcon fontSize="small" /> 1920x1080
                </a>
              </>
            )}
            {props.field.name == 'landscape' && (
              <>
                <a href={`${config.CDN_URL}/r/${dataRef.current.hash}_1080x1920.jpg?v=${version}`} target="_blank" className={classes.a}>
                  <LaunchIcon fontSize="small" /> 1080x1920
                </a>
              </>
            )}
          </>
        )}
        {/* {(props.field.name == 'portrait' || props.field.name == 'landscape') && <Checkbox checked={dataRef.current && dataRef.current[props.field.name]} onChange={handleChange} name={props.field.name} />} */}
        {(props.field.name == 'portrait' || props.field.name == 'landscape') && allInputs.CheckboxComponent(props)}
      </div>
    </div>
  );
}

function ContributerRelationalStatus(props: GridComponentProp) {
  return <div>S: {LibService.instance().translatEnum(contributerApiEnums.ContributerStatus, 'ContributerStatus', props.rowData.contributer.status)}</div>;
}

function ContributerImageListComponent(props: GridComponentProp) {
  return ContributerImageDetailComponent(props);
}
function ContributerImageDetailComponent(props: GridComponentProp) {
  const [loading, setLoading] = useState(false);
  const [showBtn, setShowBtn] = useState(false);
  const [version, setVersion] = useState(new Date().getTime());
  const resizeImage = () => {
    setLoading(true);
    new ApiBusinessLogic()
      .request('POST', '/ContributerApi/ContributerImageSearch/resizeThumb', { id: props.rowData.id })
      .then((response) => {
        setShowBtn(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onError = () => {
    setShowBtn(true);
  };

  var value = config.CDN_URL + '/' + props.rowData.path;
  var thumb = config.CDN_URL + '/t/' + props.rowData.id + '.jpg?v=' + version;
  return (
    <>
      {!showBtn && (
        <a href={value} target="_blank">
          <img src={thumb} style={{ maxWidth: 200, maxHeight: 200 }} alt={'IMG'} onError={onError} />
        </a>
      )}
      {showBtn && (
        <Button
          disabled={loading}
          size="small"
          variant="text"
          color="secondary"
          startIcon={loading ? <CircularProgress color="secondary" size="1em" /> : <ImageIcon />}
          onClick={resizeImage}
        >
          Generate
        </Button>
      )}
    </>
  );
}

function ContributerDocumentComponent(props: GridComponentProp) {
  const [documents, setDocuments] = useState([]);

  useEffect(() => {
    UIManager.instance().displayLoading(true);
    new ApiBusinessLogic()
      .request('POST', '/ContributerApi/ContributerImageDocumentSearch/all', { Filter: { ContributerImageId: props.rowData.id } })
      .then((response) => {
        setDocuments(response.value);
      })
      .finally(() => {
        UIManager.instance().displayLoading(false);
      });
  }, []);

  return (
    <div>
      {' '}
      {documents.map((value: any, index) => (
        <div key={index}>
          <p>
            <a href={config.CDN_URL + '/' + value.path} target="_blank">
              {' '}
              {value.fileName}{' '}
            </a>
          </p>
        </div>
      ))}
    </div>
  );
}

function ContributerDocumentListComponent(props: GridComponentProp) {
  const [documents, setDocuments] = useState<any>([]);

  useEffect(() => {
    setDocuments(
      props.rowData.docs.map((item, index) => {
        item.ext = item.path.split('.').pop();
        if (['jpg', 'jpeg', 'gif', 'png'].indexOf(item.ext) != -1) {
          item.type = 'image';
        } else {
          item.type = 'none';
        }
        return item;
      })
    );
  }, []);

  return (
    <div className="my-grid" style={{ width: 300 }}>
      {documents.map((item, index) => {
        return (
          <a
            key={index}
            className="my-grid-col-2 grid-center grid-square border"
            style={{ fontSize: 16 }}
            href={config.CDN_URL + '/' + item.path}
            target="_blank"
          >
            {item.type == 'none' && (
              <>
                <LaunchIcon fontSize="small" /> {item.ext}
              </>
            )}
            {item.type == 'image' && <div className="fill" style={{ backgroundImage: `url(${config.CDN_URL}/${item.path})`, backgroundSize: 'cover' }}></div>}
          </a>
        );
      })}
    </div>
  );
}

function ContributerDocumentDisplayComponent(props: GridComponentProp) {
  const [type, setType] = useState('none');
  const [extension, setExtension] = useState(props.rowData.path.split('.').pop());

  useEffect(() => {
    if (['jpg', 'jpeg', 'gif', 'png'].indexOf(extension) != -1) {
      setType('image');
    }
  }, []);

  return (
    <div>
      <a href={config.CDN_URL + '/' + props.rowData.path} target="_blank">
        {type == 'none' && (
          <span style={{ fontSize: 16, display: 'inline-flex', padding: 20 }}>
            <LaunchIcon fontSize="small" /> {extension}
          </span>
        )}
        {type == 'image' && <img src={config.CDN_URL + '/' + props.rowData.path} style={{ width: 200 }} alt={props.rowData.path} />}
      </a>
    </div>
  );
}

function ContributerPlanComponent(props: GridComponentProp) {
  var contributerPlan = props.rowData.contributerPlan;
  var contributerPlanValue = '';
  if (Object.keys(contributerPlan).length !== 0) {
    contributerPlanValue = contributerPlan.name + ':(£' + contributerPlan.commission + ' - £' + contributerPlan.payoutThreshold + ')';
  }
  return <span>{contributerPlanValue}</span>;
}

function UrlComponent(props: DetailComponentProp) {
  var link = String(props.rowData);
  if (link.includes('http') == false) {
    link = 'https://' + link;
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={4}>
        <strong>{LibService.instance().getFieldLabel(props.pageConfig, props.field.name)}</strong>
      </Grid>
      <Grid item xs={8}>
        :{' '}
        <a href={link} target="_blank">
          {link}
        </a>
      </Grid>
    </Grid>
  );
}

function ContributerNameGridComponent(props: GridComponentProp) {
  if (!props.rowData.contributer) return <span>N/A</span>;
  return (
    <a href={'#/panel/ContributerApi/ContributerSearch/detail/' + props.rowData.contributerId} target="_blank">
      {props.rowData.contributer.name}
      <br />
      {props.rowData.contributer.email}
    </a>
  );
}

var CustomComponents = {
  ...allInputs,
  GridImageComponent,
  DetailImageComponent,
  UpsertImageComponent,
  QueryBuilderComponent,
  ReadonlyImageComponent,
  ImageInlineCropComponent,
  UrlComponent,
  ContributerRelationalStatus,
  ContributerPlanComponent,
  ContributerImageListComponent,
  ContributerImageDetailComponent,
  ContributerDocumentComponent,
  ContributerDocumentDisplayComponent,
  ContributerDocumentListComponent,
  ContributerNameGridComponent,
};

// override components
//CustomComponents.InputComponent = SomeComponent;

export default CustomComponents;
