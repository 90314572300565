import { PageConfig, PageField } from 'onka-react-admin-core';
import CC from '../../components/CustomComponents';
import adzyCustomEnums from '../../modules/adzyApi/adzyCustomEnums';

export const pageConfig = new PageConfig({
  route: '/AdzyApi/AlgorithmTest',
  moduleKey: 'AdzyApi',
  pageKey: 'AlgorithmTest',
  menu: 'AdzyApi',
  menuOrder: 0,
  filterFields: [
    new PageField({
      name: 'algoId',
      reference: {
        route: 'AdzyApi/AlgorithmSearch',
        filterField: 'name',
        dataField: 'algo',
        limit: 0
      },
      filterComponent: CC.ReferenceComponent,
    }),
    new PageField({
      name: 'parentSectorId',
      reference: {
        route: 'AdzyApi/SectorSearch',
        filterField: 'name',
        dataField: 'parentSector',
        limit: 0
      },
      filterComponent: CC.ReferenceComponent,
      depends: [{ name: 'parentId', value: 'null' }],
    }),
    new PageField({
      name: 'sectorId',
      reference: {
        route: 'AdzyApi/SectorSearch',
        filterField: 'name',
        dataField: 'sector',
        sortField: 'parentId',
        limit: 0
      },
      filterComponent: CC.ReferenceComponent,
      depends: [{ name: 'parentId', field: 'parentSectorId' }],
    }),
    new PageField({
      name: 'emotionId',
      reference: {
        route: 'AdzyApi/EmotionSearch',
        filterField: 'name',
        dataField: 'emotion',        
        limit: 0
      },
      filterComponent: CC.ReferenceComponent,
      depends: [{ name: 'parentId', value: 'null' }],
    }),
    new PageField({
      name: 'purposeId',
      reference: {
        route: 'AdzyApi/PurposeSearch',
        filterField: 'title',
        dataField: 'purpose',
        sortField: 'adminPosition',
        limit: 0
      },
      filterComponent: CC.ReferenceComponent,
    }),
    new PageField({
      name: 'includeSimpleResults', 
      filterComponent: CC.CheckboxComponent,
    }),
  ],
});
