// Auto generated file
// Do not change '//Generator' comments
import { RouteItem } from 'onka-react-admin-core';
import { route as emailHistorySearchRoute } from './pages/emailHistorySearch';
import { route as emailSettingSearchRoute } from './pages/emailSettingSearch';
import { route as emailTemplateSearchRoute } from './pages/emailTemplateSearch';
//Generator1

const routes: RouteItem[] = [
emailHistorySearchRoute,
emailSettingSearchRoute,
emailTemplateSearchRoute,
//Generator2
];

export default routes;