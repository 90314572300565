import React from 'react';
import { Operators, Widgets, Fields, Config, Types, Conjunctions, Settings, BasicConfig } from '@react-awesome-query-builder/ui';
import { enUS } from '@mui/material/locale';
import { merge } from 'lodash';

const InitialConfig = BasicConfig;

const selectableEmotionParentOptions = [
  { value: '{user_selected_emotion2}', title: 'User Selected Emotion (Parent)' },
  { value: '{parent_usi_emotion}', title: 'Parent Emotion of Selected Image' },
  { value: '{system_emotion_parent}', title: 'System Emotion (Parent)' },
  { value: '{system_emotion_parent_1}', title: 'System Emotion (Parent) 1' },
  { value: '{system_emotion_parent_2}', title: 'System Emotion (Parent) 2' },
  { value: '{system_emotion_parent_3}', title: 'System Emotion (Parent) 3' },
  { value: '{system_emotion_parent_4}', title: 'System Emotion (Parent) 4' },
  { value: '{system_emotion_parent_5}', title: 'System Emotion (Parent) 5' },
  { value: '{system_emotion_parent_6}', title: 'System Emotion (Parent) 6' },
  { value: '{system_emotion_parent_7}', title: 'System Emotion (Parent) 7' },
  { value: '{system_emotion_parent_8}', title: 'System Emotion (Parent) 8' },
  { value: '{system_emotion_parent_9}', title: 'System Emotion (Parent) 9' },
  { value: "|!!ObjectId('5d848a22b3e4c524475f73ab')!!|", title: 'Security' },
  { value: "|!!ObjectId('5d848a0db3e4c524475f73a8')!!|", title: 'Happy' },
  { value: "|!!ObjectId('5d848a28b3e4c524475f73ac')!!|", title: 'Interest' },
  { value: "|!!ObjectId('5d848a15b3e4c524475f73a9')!!|", title: 'Indulgence' },
  { value: "|!!ObjectId('5d848a36b3e4c524475f73ae')!!|", title: 'Entertained' },
  { value: "|!!ObjectId('5d848a2fb3e4c524475f73ad')!!|", title: 'Optimism' },
  { value: "|!!ObjectId('5d549dcdbac09f4ac0f3d70b')!!|", title: 'Attraction' },
  { value: "|!!ObjectId('5d848a1cb3e4c524475f73aa')!!|", title: 'Recognition' },
];

const selectableEmotionOptions = [
  // { value: '{user_selected_emotion}', title: 'User Selected Emotion (Parent)' },
  { value: '{emotion_child_0}', title: 'Emotion Child 1' },
  { value: '{emotion_child_1}', title: 'Emotion Child 2' },
  { value: '{emotion_child_2}', title: 'Emotion Child 3' },
  { value: '{emotion_child_3}', title: 'Emotion Child 4' },
  { value: '{emotion_child_4}', title: 'Emotion Child 5' },
  { value: '{emotion_child_5}', title: 'Emotion Child 6' },
  { value: '{emotion_child_6}', title: 'Emotion Child 7' },
  { value: '{emotion_child_7}', title: 'Emotion Child 8' },
  { value: '{emotion_child_8}', title: 'Emotion Child 9' },
  { value: '{initial_adset_emotion}', title: 'Initial Adset Emotion' },
  { value: '{random_usi_emotion}', title: 'Random Child Emotion of User Selected Image' },
];

const selectableSectorOptions = [
  { value: '{user_selected_sector}', title: 'User Selected Sector' },
  { value: '{user_selected_sector_parent}', title: 'User Selected Sector Parent' },
  { value: '{sector_child_0}', title: 'Sector Child 1' },
  { value: '{sector_child_1}', title: 'Sector Child 2' },
  { value: '{sector_child_2}', title: 'Sector Child 3' },
  { value: '{sector_child_3}', title: 'Sector Child 4' },
  { value: '{sector_child_4}', title: 'Sector Child 5' },
  { value: '{sector_child_5}', title: 'Sector Child 6' },
  { value: '{sector_child_6}', title: 'Sector Child 7' },
  { value: '{sector_child_7}', title: 'Sector Child 8' },
  { value: '{sector_child_8}', title: 'Sector Child 9' },
  { value: '{initial_adset_sector}', title: 'Initial Adset Sector' },
];

const selectableReasonOptions = [
  { value: '{user_selected_reason}', title: 'User Selected Reason' },
  { value: '{user_selected_reason_parent}', title: 'User Selected Reason Parent' },
  { value: '{reason_child_0}', title: 'Reason Child 1' },
  { value: '{reason_child_1}', title: 'Reason Child 2' },
  { value: '{reason_child_2}', title: 'Reason Child 3' },
  { value: '{reason_child_3}', title: 'Reason Child 4' },
  { value: '{reason_child_4}', title: 'Reason Child 5' },
  { value: '{reason_child_5}', title: 'Reason Child 6' },
  { value: '{reason_child_6}', title: 'Reason Child 7' },
  { value: '{reason_child_7}', title: 'Reason Child 8' },
  { value: '{reason_child_8}', title: 'Reason Child 9' },
  { value: '{initial_adset_reason}', title: 'Initial Adset Reason' },
];

const selectablePurposeOptions = [
  { value: '{user_selected_purpose}', title: 'User Selected Purpose' },
  { value: '{purpose_child_0}', title: 'Purpose Child 1' },
  { value: '{purpose_child_1}', title: 'Purpose Child 2' },
  { value: '{purpose_child_2}', title: 'Purpose Child 3' },
  { value: '{purpose_child_3}', title: 'Purpose Child 4' },
  { value: '{purpose_child_4}', title: 'Purpose Child 5' },
  { value: '{purpose_child_5}', title: 'Purpose Child 6' },
  { value: '{purpose_child_6}', title: 'Purpose Child 7' },
  { value: '{purpose_child_7}', title: 'Purpose Child 8' },
  { value: '{purpose_child_8}', title: 'Purpose Child 9' },
  { value: '{is_seasonal}', title: 'Is Seasonal' },
  { value: '{is_sale}', title: 'Is Sale' },
  { value: '{initial_adset_purpose}', title: 'Initial Adset Purpose' },
];

const conjunctions: Conjunctions = merge(InitialConfig.conjunctions, {
  AND: {
    label: 'And',
    mongoConj: '$and',
    reversedConj: 'OR',
    formatConj: (children, conj, not, isForDisplay) => {
      return children.size > 1
        ? (not ? 'NOT ' : '') + '(' + children.join(' ' + (isForDisplay ? 'AND' : '&&') + ' ') + ')'
        : (not ? 'NOT (' : '') + children.first() + (not ? ')' : '');
    },
  },
  OR: {
    label: 'Or',
    mongoConj: '$or',
    reversedConj: 'AND',
    formatConj: (children, conj, not, isForDisplay) => {
      return children.size > 1
        ? (not ? 'NOT ' : '') + '(' + children.join(' ' + (isForDisplay ? 'OR' : '||') + ' ') + ')'
        : (not ? 'NOT (' : '') + children.first() + (not ? ')' : '');
    },
  },
});

const operators: Operators = merge(
  InitialConfig.operators,

  {
    equal: {
      label: '==',
      labelForFormat: '==',
      reversedOp: 'not_equal',
      mongoFormatOp: (field, op, value) => ({ [field]: { $eq: value } }),
    },
    not_equal: {
      label: '!=',
      labelForFormat: '!=',
      reversedOp: 'equal',
      mongoFormatOp: (field, op, value) => ({ [field]: { $ne: value } }),
    },
    equal_bool: {
      label: '==',
      labelForFormat: '==',
      reversedOp: 'not_equal_bool',
      mongoFormatOp: (field, op, value) => ({ [field]: value }),
    },
    equal_bool_wildcard: {
      label: '==',
      labelForFormat: '==',
      reversedOp: 'not_equal_bool_wildcard',
      mongoFormatOp: (field, op, value) => ({
        //$or: [{ $and: [{ [field]: { $exists: true } }, { [field]: value }] }, { [field]: { $exists: false } }],
        [field]: value,
      }),
    },
    not_equal_bool_wildcard: {
      label: '!==',
      labelForFormat: '!==',
      reversedOp: 'equal_bool_wildcard',
      mongoFormatOp: (field, op, value) => ({
        //$or: [{ [field]: { $exists: false } }, { [field]: { $exists: true, $ne: value } }],
        [field]: value,
      }),
    },
    not_equal_bool: {
      label: '!==',
      labelForFormat: '!==',
      reversedOp: 'equal_bool',
      mongoFormatOp: (field, op, value) => ({ [field]: { $ne: value } }),
    },
    less: {
      label: '<',
      labelForFormat: '<',
      reversedOp: 'greater_or_equal',
      mongoFormatOp: (field, op, value) => ({ [field]: { $lt: value } }),
    },
    less_or_equal: {
      label: '<=',
      labelForFormat: '<=',
      reversedOp: 'greater',
      mongoFormatOp: (field, op, value) => ({ [field]: { $lte: value } }),
    },
    greater: {
      label: '>',
      labelForFormat: '>',
      reversedOp: 'less_or_equal',
      mongoFormatOp: (field, op, value) => ({ [field]: { $gt: value } }),
    },
    greater_or_equal: {
      label: '>=',
      labelForFormat: '>=',
      reversedOp: 'less',
      mongoFormatOp: (field, op, value) => ({ [field]: { $gte: value } }),
    },
    between: {
      label: 'Between',
      labelForFormat: 'BETWEEN',
      cardinality: 2,
      formatOp: (field, op, values: any, valueSrcs, valueTypes, opDef, operatorOptions, isForDisplay) => {
        let valFrom = values.first();
        let valTo = values.get(1);
        if (isForDisplay) return `${field} >= ${valFrom} AND ${field} <= ${valTo}`;
        else return `${field} >= ${valFrom} && ${field} <= ${valTo}`;
      },
      mongoFormatOp: (field, op, values) => ({ [field]: { $gte: values[0], $lte: values[1] } }),
      valueLabels: ['Value from', 'Value to'],
      textSeparators: [null, 'and'],
      reversedOp: 'not_between',
    },
    not_between: {
      label: 'Not between',
      labelForFormat: 'NOT BETWEEN',
      cardinality: 2,
      mongoFormatOp: (field, op, values) => ({
        [field]: { $not: { $gte: values[0], $lte: values[1] } },
      }),
      valueLabels: ['Value from', 'Value to'],
      textSeparators: [null, 'and'],
      reversedOp: 'between',
    },
    range_between: {
      label: 'Between',
      labelForFormat: 'BETWEEN',
      cardinality: 2,
      isSpecialRange: true, // to show 1 range widget instead of 2
      formatOp: (field, op, values: any, valueSrcs, valueTypes, opDef, operatorOptions, isForDisplay) => {
        let valFrom = values.first();
        let valTo = values.get(1);
        if (isForDisplay) return `${field} >= ${valFrom} AND ${field} <= ${valTo}`;
        else return `${field} >= ${valFrom} && ${field} <= ${valTo}`;
      },
      mongoFormatOp: (field, op, values) => ({ [field]: { $gte: values[0], $lte: values[1] } }),
      valueLabels: ['Value from', 'Value to'],
      textSeparators: [null, 'and'],
      reversedOp: 'range_not_between',
    },
    range_not_between: {
      label: 'Not between',
      labelForFormat: 'NOT BETWEEN',
      cardinality: 2,
      isSpecialRange: true, // to show 1 range widget instead of 2
      mongoFormatOp: (field, op, values) => ({
        [field]: { $not: { $gte: values[0], $lte: values[1] } },
      }),
      valueLabels: ['Value from', 'Value to'],
      textSeparators: [null, 'and'],
      reversedOp: 'range_between',
    },
    is_empty: {
      isUnary: true,
      label: 'Is Empty',
      labelForFormat: 'IS EMPTY',
      cardinality: 0,
      reversedOp: 'is_not_empty',
      formatOp: (field, op, value, valueSrc, valueType, opDef, operatorOptions, isForDisplay) => {
        return isForDisplay ? `${field} IS EMPTY` : `!${field}`;
      },
      mongoFormatOp: (field, op) => ({ [field]: { $exists: false } }),
    },
    is_not_empty: {
      isUnary: true,
      label: 'Is not empty',
      labelForFormat: 'IS NOT EMPTY',
      cardinality: 0,
      reversedOp: 'is_empty',
      formatOp: (field, op, value, valueSrc, valueType, opDef, operatorOptions, isForDisplay) => {
        return isForDisplay ? `${field} IS NOT EMPTY` : `!!${field}`;
      },
      mongoFormatOp: (field, op) => ({ [field]: { $exists: true } }),
    },
    select_equals: {
      label: '==',
      labelForFormat: '==',
      formatOp: (field, op, value, valueSrc, valueType, opDef, operatorOptions, isForDisplay) => {
        return `${field} == ${value}`;
      },
      mongoFormatOp: (field, op, value) => {
        return {
          [field]: { $eq: value },
          // $and: [
          //   { [field]: { $type: 'array' } },
          //   {
          //     $or: [
          //       { [field]: { $eq: value } },
          //       {
          //         $or: [{ [field]: { $eq: '' } }, { [field]: { $size: 0 } }],
          //       },
          //     ],
          //   },
          // ],
        };
      },
      reversedOp: 'select_not_equals',
    },

    select_not_equals: {
      label: '!=',
      labelForFormat: '!=',
      formatOp: (field, op, value, valueSrc, valueType, opDef, operatorOptions, isForDisplay) => {
        return `${field} != ${value}`;
      },
      mongoFormatOp: (field, op, value) => {
        return {
          [field]: { $ne: value },
          // $or: [
          //   {
          //     $and: [
          //       { [field]: { $type: 'array' } },
          //       {
          //         $or: [
          //           { [field]: { $ne: value } },
          //           {
          //             $or: [{ [field]: { $eq: '' } }, { [field]: { $size: 0 } }],
          //           },
          //         ],
          //       },
          //     ],
          //   },
          // ],
        };
      },
      reversedOp: 'select_equals',
    },
    select_purpose: {
      label: '==',
      labelForFormat: '==',
      formatOp: (field, op, values, valueSrc, valueType, opDef, operatorOptions, isForDisplay) => {
        if (valueSrc == 'value') return `${field} == (${(values && Array.isArray(values) && values.join(', ')) || values})`;
        else return `${field} == (${values})`;
      },
      mongoFormatOp: (field, op, values) => ({ [field]: { $in: values } }),
      reversedOp: 'select_not_purpose',
    },
    select_not_purpose: {
      label: '!==',
      labelForFormat: '!=',
      formatOp: (field, op, values: any, valueSrc, valueType, opDef, operatorOptions, isForDisplay) => {
        if (valueSrc == 'value') return `${field} !== (${values.join(', ')})`;
        else return `${field} !== (${values})`;
      },
      mongoFormatOp: (field, op, values) => ({ [field]: { $nin: values } }),
      reversedOp: 'select_purpose',
    },
    select_any_in: {
      label: 'Any in',
      labelForFormat: 'IN',
      formatOp: (field, op, values: any, valueSrc, valueType, opDef, operatorOptions, isForDisplay) => {
        if (valueSrc == 'value') return `${field} IN (${values.join(', ')})`;
        else return `${field} IN (${values})`;
      },
      mongoFormatOp: (field, op, values) => ({ [field]: { $in: values } }),
      reversedOp: 'select_not_any_in',
    },
    select_not_any_in: {
      label: 'Not in',
      labelForFormat: 'NOT IN',
      formatOp: (field, op, values: any, valueSrc, valueType, opDef, operatorOptions, isForDisplay) => {
        if (valueSrc == 'value') return `${field} NOT IN (${values.join(', ')})`;
        else return `${field} NOT IN (${values})`;
      },
      mongoFormatOp: (field, op, values) => ({ [field]: { $nin: values } }),
      reversedOp: 'select_any_in',
    },
    multiselect_equals: {
      label: 'Equals',
      labelForFormat: '==',
      formatOp: (field, op, values: any, valueSrc, valueType, opDef, operatorOptions, isForDisplay) => {
        if (valueSrc == 'value') return `${field} == [${values.join(', ')}]`;
        else return `${field} == ${values}`;
      },
      mongoFormatOp: (field, op, values) => ({ [field]: { $eq: values } }),
      reversedOp: 'multiselect_not_equals',
    },
    multiselect_not_equals: {
      label: 'Not equals',
      labelForFormat: '!=',
      formatOp: (field, op, values: any, valueSrc, valueType, opDef, operatorOptions, isForDisplay) => {
        if (valueSrc == 'value') return `${field} != [${values.join(', ')}]`;
        else return `${field} != ${values}`;
      },
      mongoFormatOp: (field, op, values) => ({ [field]: { $ne: values } }),
      reversedOp: 'multiselect_equals',
    },
    // proximity: {
    //   label: 'Proximity search',
    //   cardinality: 2,
    //   valueLabels: [{ label: 'Word 1', placeholder: 'Enter first word' }, 'Word 2'],
    //   textSeparators: [
    //     //'Word 1',
    //     //'Word 2'
    //   ],
    //   formatOp: (field, op, values: any, valueSrc, valueType, opDef, operatorOptions: any, isForDisplay) => {
    //     let val1 = values.first();
    //     let val2 = values.get(1);
    //     return `${field} ${val1} NEAR/${operatorOptions.get('proximity')} ${val2}`;
    //   },
    //   mongoFormatOp: (field, op, values) => undefined,
    //   options: {
    //     optionLabel: 'Near',
    //     optionTextBefore: 'Near',
    //     optionPlaceholder: 'Select words between',
    //     factory: (props) => <ProximityOperator {...props} />,
    //     defaults: {
    //       proximity: 2,
    //     },
    //   },
    // },
  }
);

const widgets: Widgets = {
  ...InitialConfig.widgets,
  // examples of  overriding
  text: {
    ...InitialConfig.widgets.text,
  },
  slider: {
    ...InitialConfig.widgets.slider,
    customProps: {
      width: '300px',
    },
  },
  rangeslider: {
    ...InitialConfig.widgets.rangeslider,
    customProps: {
      width: '300px',
    },
  },
  date: {
    ...InitialConfig.widgets.date,
    dateFormat: 'DD.MM.YYYY',
    valueFormat: 'YYYY-MM-DD',
  },
  time: {
    ...InitialConfig.widgets.time,
    timeFormat: 'HH:mm',
    valueFormat: 'HH:mm:ss',
  },
  datetime: {
    ...InitialConfig.widgets.datetime,
    timeFormat: 'HH:mm',
    dateFormat: 'DD.MM.YYYY',
    valueFormat: 'YYYY-MM-DD HH:mm:ss',
  },
  func: {
    ...InitialConfig.widgets.func,
    customProps: {
      showSearch: true,
    },
  },
  treeselect: {
    ...InitialConfig.widgets.treeselect,
    customProps: {
      showSearch: true,
    },
  },
};

const types: Types = merge(InitialConfig.types, {
  text: {
    widgets: {
      text: {
        defaultOperator: 'is_empty',
        operators: ['equal', 'not_equal', 'is_empty', 'is_not_empty', 'proximity'],
        widgetProps: {
          formatValue: (val, fieldDef, wgtDef, isForDisplay) => JSON.stringify(val),
          valueLabel: 'Text',
          valuePlaceholder: 'Enter text',
        },
      },
      field: {
        operators: [
          'equal',
          'not_equal',
          //note that unary ops will be excluded anyway, see getWidgetsForFieldOp()
          //"is_empty",
          //"is_not_empty",
          'proximity',
        ],
      },
    },
  },
  number: {
    mainWidget: 'number',
    valueSources: ['value', 'field'],
    defaultOperator: 'equal',
    widgets: {
      number: {
        operators: ['equal', 'not_equal', 'less', 'less_or_equal', 'greater', 'greater_or_equal', 'between', 'not_between', 'is_empty', 'is_not_empty'],
        widgetProps: {
          valueLabel: 'Number2',
          valuePlaceholder: 'Enter number2',
        },
      },
      slider: {
        operators: ['equal', 'not_equal', 'less', 'less_or_equal', 'greater', 'greater_or_equal'],
        widgetProps: {
          valueLabel: 'Slider',
          valuePlaceholder: 'Move slider',
          customProps: {
            width: '200px',
          },
        },
      },
      rangeslider: {
        operators: ['range_between', 'range_not_between'],
        widgetProps: {
          valueLabel: 'Range',
          valuePlaceholder: 'Select range',
          customProps: {
            width: '300px',
          },
        },
      },
    },
  },
  date: {
    widgets: {
      date: {
        operators: ['equal', 'not_equal', 'less', 'less_or_equal', 'greater', 'greater_or_equal', 'between', 'not_between', 'is_empty', 'is_not_empty'],
      },
    },
  },
  time: {
    widgets: {
      time: {
        operators: ['equal', 'not_equal', 'less', 'less_or_equal', 'greater', 'greater_or_equal', 'between', 'not_between', 'is_empty', 'is_not_empty'],
      },
    },
  },
  datetime: {
    widgets: {
      datetime: {
        operators: ['equal', 'not_equal', 'less', 'less_or_equal', 'greater', 'greater_or_equal', 'between', 'not_between', 'is_empty', 'is_not_empty'],
        opProps: {
          between: {
            valueLabels: [
              { label: 'Date from', placeholder: 'Enter datetime from' },
              { label: 'Date to', placeholder: 'Enter datetime to' },
            ],
          },
        },
        widgetProps: {
          timeFormat: 'HH:mm',
          dateFormat: 'YYYY-MM-DD',
          valueFormat: 'YYYY-MM-DD HH:mm',
        },
      },
    },
  },
  select: {
    mainWidget: 'select',
    widgets: {
      select: {
        operators: ['select_equals', 'select_not_equals', 'select_purpose', 'select_not_purpose'],
        widgetProps: {
          customProps: {
            showSearch: true,
          },
        },
      },
      multiselect: {
        operators: ['select_any_in', 'select_not_any_in'],
        widgetProps: {},
      },
    },
  },
  multiselect: {
    widgets: {
      multiselect: {
        operators: ['multiselect_equals', 'multiselect_not_equals'],
      },
    },
  },
  boolean: {
    widgets: {
      boolean: {
        operators: ['equal', 'not_equal', 'equal_bool_wildcard', 'not_equal_bool_wildcard'],
        widgetProps: {
          //you can enable this if you don't use fields as value sources
          //hideOperator: true,
          //operatorInlineLabel: "is",
        },
      },
      field: {
        operators: ['equal_bool_wildcard', 'not_equal_bool_wildcard', 'equal', 'not_equal'],
      },
    },
  },
});

const settings: Settings = merge(InitialConfig.settings, {
  locale: {
    short: 'en',
    full: 'en-US',
    material: enUS,
  },
  maxLabelsLength: 80,
  hideConjForOne: false,
  renderSize: 'default',
  renderConjsAsRadios: true,
  renderFieldAndOpAsDropdown: true,
  customFieldSelectProps: {
    showSearch: true,
  },
  groupActionsPosition: 'topRight', // oneOf [topLeft, topCenter, topRight, bottomLeft, bottomCenter, bottomRight]
  setOpOnChangeField: ['keep', 'default'], // 'default' (default if present), 'keep' (keep prev from last field), 'first', 'none'
  clearValueOnChangeField: false, //false - if prev & next fields have same type (widget), keep
  clearValueOnChangeOp: false,
  setDefaultFieldAndOp: false,
  maxNesting: 10,
  fieldSeparator: '.',
  fieldSeparatorDisplay: '->',
  showLabels: false,
  valueLabel: 'Value',
  valuePlaceholder: 'Value',
  fieldLabel: 'Field',
  operatorLabel: 'Operator',
  fieldPlaceholder: 'Select field',
  operatorPlaceholder: 'Select operator',
  deleteLabel: null,
  addGroupLabel: 'Add Image',
  addRuleLabel: 'Add condition',
  readonlyMode: false,
  notLabel: 'Not',
  showNot: true,
  delGroupLabel: null,
  canLeaveEmptyGroup: true, //after deletion
  formatReverse: (q, operator, reversedOp, operatorDefinition, revOperatorDefinition, isForDisplay) => {
    if (isForDisplay) return 'NOT(' + q + ')';
    else return '!(' + q + ')';
  },
  formatField: (field, parts, label2, fieldDefinition, config, isForDisplay) => {
    if (isForDisplay) return label2;
    else return field;
  },
  valueSourcesInfo: {
    value: {
      label: 'Value',
    },
    field: {
      label: 'Field',
      widget: 'field',
    },
  },
  valueSourcesPopupTitle: 'Select value source',
  canReorder: true,
  canCompareFieldWithField: (leftField, leftFieldConfig, rightField, rightFieldConfig) => {
    //for type == 'select'/'multiselect' you can check listValues
    return true;
  },
});

const fields: Fields = {
  parentEmotionId: {
    label: 'Emotion (Parent)',
    type: 'select',
    defaultOperator: 'select_equals',
    operators: ['select_equals', 'select_not_equals'],
    listValues: selectableEmotionParentOptions,
    valueSources: ['value'],
  },
  emotionIds: {
    label: 'Emotion',
    type: 'select',
    defaultOperator: 'select_equals',
    operators: ['select_equals', 'select_not_equals'],
    listValues: selectableEmotionOptions,
    valueSources: ['value'],
  },
  sectorIds: {
    label: 'Sector',
    type: 'select',
    defaultOperator: 'select_equals',
    operators: ['select_equals', 'select_not_equals'],
    listValues: selectableSectorOptions,
    valueSources: ['value'],
  },
  reasonIds: {
    label: 'Reason',
    type: 'select',
    defaultOperator: 'select_equals',
    operators: ['select_equals', 'select_not_equals'],
    listValues: selectableReasonOptions,
    valueSources: ['value'],
  },
  purposeIds: {
    label: 'Purpose',
    type: 'select',
    defaultOperator: 'select_equals',
    operators: ['select_equals', 'select_not_equals'],
    listValues: selectablePurposeOptions,
    valueSources: ['value'],
  },
  isWildcard: {
    label: 'Wildcard',
    type: 'boolean',
    defaultOperator: 'equal_bool_wildcard',
    operators: ['equal_bool_wildcard', 'not_equal_bool_wildcard'],
    valueSources: ['value'],
  },
  isEvent: {
    label: 'Event',
    type: 'boolean',
    defaultOperator: 'equal_bool_wildcard',
    operators: ['equal_bool_wildcard', 'not_equal_bool_wildcard'],
    valueSources: ['value'],
  },
};

const QueryBuilderConfig: Config = {
  conjunctions,
  operators,
  widgets,
  types,
  settings,
  fields,
  //funcs,
};

export default QueryBuilderConfig;
