// Auto generated file
// Do not change '//Generator' comments
import { RouteItem } from 'onka-react-admin-core';
import { route as settingsSearchRoute } from './pages/settingsSearch';
import { route as userEventSearchRoute } from './pages/userEventSearch';
//Generator1

const routes: RouteItem[] = [
settingsSearchRoute,
userEventSearchRoute,
//Generator2
];

export default routes;