import React, { memo, useCallback } from 'react';
import { Query, Builder, Utils as QbUtils, Config, JsonTree, ImmutableTree } from '@react-awesome-query-builder/ui';

import '@react-awesome-query-builder/ui/css/styles.css';
import '@react-awesome-query-builder/ui/css/compact_styles.css';
//import '@react-awesome-query-builder/ui/css/denormalize.css';
import './style.scss';
import QueryBuilderConfig from './QueryBuilderConfig';

const config: any = QueryBuilderConfig;

export interface QueryStringChanged {
  mongoGroups: string[];
  mongo: string;
  sql: string | undefined;
  tree: JsonTree;
}

const QueryBuilder = memo(({ data, onChange }: { data: any; onChange: (e: QueryStringChanged) => void }) => {
  const onTreeChange = useCallback((immutableTree: ImmutableTree, config: Config) => {
    var tree = QbUtils.getTree(immutableTree);
    var children1: any = immutableTree.get('children1');
    var count = children1.count();
    var mongos: string[] = [];
    for (let i = 0; i < count; i++) {
      var cloned = immutableTree.asImmutable();
      cloned = cloned.set('children1', children1.slice(i, i + 1));
      mongos.push(JSON.stringify(QbUtils.mongodbFormat(cloned, config)));
    }
    console.log('onTreeChange', immutableTree, tree);
    onChange({
      mongoGroups: mongos,
      mongo: JSON.stringify(QbUtils.mongodbFormat(immutableTree, config), undefined, 2),
      sql: QbUtils.queryString(immutableTree, config, true),
      tree: tree,
    });
  }, []);

  var renderBuilder = useCallback(
    (props) => (
      <div className="query-builder-container" style={{ }}>
        <div className="query-builder qb-lite">
          <Builder {...props} />
        </div>
      </div>
    ),
    []
  );

  console.log('QB data', data);
  console.log('QB config', config);

  if (!data) return <div></div>;

  return (
    <div>
      {/* eslint-disable-next-line */}
      <Query {...config} onChange={onTreeChange} renderBuilder={renderBuilder} value={data} />
    </div>
  );
});

export default QueryBuilder;
