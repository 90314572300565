import React from 'react';
import { Route } from 'react-router-dom';
import { ConfigService, Admin, Menu, StaticService, AccountBusinessLogic, defaultRequestOptions } from 'onka-react-admin-core';
import AdminIcon from '@mui/icons-material/Person';
import SettingsIcon from '@mui/icons-material/Settings';
import AdzyIcon from '@mui/icons-material/RadioButtonUnchecked';
import EmailIcon from '@mui/icons-material/EmailOutlined';
import PaymentIcon from '@mui/icons-material/PaymentOutlined';
import ContributerIcon from '@mui/icons-material/Accessibility';
import About from './ui/public/About';
import config from './data/config';
import panelRoutes from './ui/panel/modules';
import Dashboard from './ui/panel/pages/Dashboard';
import { Footer } from './ui/panel/pages/Footer';
import { PublicFooter } from './ui/panel/pages/PublicFooter';
import './ext/scripts';
import { createTheme } from '@mui/material/styles';
import { Theme } from '@mui/system';

export const menus: Menu[] = [
  new Menu({
    menuKey: 'AdminApi',
    routes: [],
    hasAccess: false,
    icon: <AdminIcon />,
  }),
  new Menu({
    menuKey: 'AdzyApi',
    routes: [],
    hasAccess: false,
    icon: <AdzyIcon />,
  }),
  new Menu({
    menuKey: 'EmailApi',
    routes: [],
    hasAccess: false,
    icon: <EmailIcon />,
  }),
  new Menu({
    menuKey: 'PaymentApi',
    routes: [],
    hasAccess: false,
    icon: <PaymentIcon />,
  }),
  new Menu({
    menuKey: 'Settings',
    routes: [],
    hasAccess: false,
    icon: <SettingsIcon />,
  }),
  new Menu({
    menuKey: 'ContributerApi',
    routes: [],
    hasAccess: false,
    icon: <ContributerIcon />,
  }),
];

const theme : Partial<Theme> | ((outerTheme: Theme) => Theme) | undefined = createTheme({
  palette: {
    primary: { main: '#77215e' },
  },
  components : {
    MuiTextField: {
      defaultProps: {
        variant: 'standard'
      }
    },
    MuiInputLabel: {
      defaultProps: {
        variant: 'standard'
      }
    },
    MuiSelect: {
      defaultProps: {
        variant: 'standard'
      }
    }
  }
});

function App() {
  console.log('App render');
  function onload(): Promise<any> {
    defaultRequestOptions.withCredentials = false;
    defaultRequestOptions.timeout = 600000;
    StaticService.TOKEN_NAME = 'AdminToken';
    AccountBusinessLogic.instance().logoutUrl = '/AdminApi/AdminUserUpdate/logout';
    ConfigService.instance().setApiUrl(config.API_URL || '');
    ConfigService.instance().setIsProd(config.IS_PROD);
    ConfigService.instance().setLangList({
      en: () => require('./ui/panel/modules/l10n/en.json'),
      tr: () => require('./ui/panel/modules/l10n/tr.json'),
    });
    return Promise.resolve();
  }
  const logo = '/images/logo.png';

  return (
    <Admin
      onLoad={onload}
      rootRoutes={[<Route key="0" path="/about" element={<About />}></Route>]}
      dashboard={<Dashboard />}
      footer={<Footer />}
      toolbar={{}}
      menu={{
        menus,
        routes: panelRoutes,
        logo,
      }}
      login={{
        footer: <PublicFooter />,
        logo,
      }}
      theme={theme}
    ></Admin>
  );
}

export default App;
