import React, { useEffect, useRef, useState } from 'react';
import { InputComponentProp, LibService, makeStyles } from 'onka-react-admin-core';
import { Theme } from '@mui/material/styles';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import QueryBuilder, { QueryStringChanged } from './QueryBuilder';
import queryBuilderConfig from './QueryBuilderConfig';
import { Utils as QbUtils, JsonTree, ImmutableTree, JsonGroup } from '@react-awesome-query-builder/ui';
import { Button, TextField } from '@mui/material';

const useStyles = makeStyles()((theme) => ({
  // root: {
  //   width: '100%',
  // },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  details: {
    display: 'block',
  },
}));

export default function QueryBuilderComponent(props: InputComponentProp) {
  const { classes } = useStyles();
  const [count, setCount] = useState(1);
  const formKey = props.pageConfig.route;

  const handleChange = (e: QueryStringChanged) => {
    console.log('XXX', e);
    props.form.handleChanges(formKey, [{ name: props.path, value: e }]);
  };
  // const emptyInitValue: JsonTree = {
  //   id: QbUtils.uuid(),
  //   type: 'group',
  //   children1: {},
  // };
  const emptyInitValue: any = {
    mongoGroups: ['{"sectorIds":{"$eq":"{user_selected_sector}"}}'],
    mongo: '{\n  "sectorIds": {\n    "$eq": "{user_selected_sector}"\n  }\n}',
    sql: 'Sector == "User Selected Sector"',
    tree: {
      id: QbUtils.uuid(),
      type: 'group',
      children1: {
        [QbUtils.uuid()]: {
          type: 'group',
          properties: { conjunction: 'AND' },
          children1: {
            [QbUtils.uuid()]: {
              type: 'rule',
              properties: { field: 'sectorIds', operator: 'select_equals', value: ['{user_selected_sector}'], valueSrc: ['value'], valueType: ['select'] },
            },
          },
        },
      },
    },
  };
  const dataRowRef = useRef<any>(emptyInitValue);
  const [value, setValue] = useState<ImmutableTree>();
  const path = LibService.instance().getPath(props.field.prefix, props.field.name);

  useEffect(() => {
    var subscription = props.form.subscribe(formKey, (data) => {
      const rowData = LibService.instance().getValue(data, path);
      if (!rowData) {
        handleChange(emptyInitValue);
        return;
      }
      dataRowRef.current = rowData;
      var loadedInitValue = rowData ? rowData.tree : null;
      console.log('loadedInitValue', loadedInitValue);

      let initValue: JsonTree = loadedInitValue && Object.keys(loadedInitValue).length > 0 ? (loadedInitValue as JsonTree) : emptyInitValue.tree;

      var initTree = QbUtils.checkTree(QbUtils.loadTree(initValue), queryBuilderConfig);
      setValue(initTree);
    });
    return () => {
      subscription.unsubscribe();
    };
  }, []);

  // clone last group and fill values
  const fillValues = (e) => {
    e.preventDefault();
    var tree: JsonGroup = dataRowRef.current.tree;
    if (!tree.children1) {
      return;
    }
    var keys = Object.keys(tree.children1);
    var lastKey = keys[keys.length - 1];

    for (let i = count - 1; i >= 0; i--) {
      tree.children1[QbUtils.uuid()] = replaceChildrenIds(tree.children1[lastKey]);
    }
    dataRowRef.current.tree = tree;
    handleChange({ ...dataRowRef.current });
  };

  const replaceChildrenIds = (child: any) => {
    if (!child.children1) return child;
    var keys = Object.keys(child.children1);
    for (let i = keys.length - 1; i >= 0; i--) {
      var key = keys[i];
      var newKey = QbUtils.uuid();
      child.children1 = renameProp(key, newKey, child.children1);
      replaceChildrenIds(child.children1[newKey]);
      console.log('replaced', key, newKey, child.children1[newKey]);
    }
    return child;
  };

  const renameProp = (oldProp, newProp, { [oldProp]: old, ...others }) => ({
    [newProp]: old,
    ...others,
  });

  console.log('QueryBuilderComponent render', JSON.stringify(dataRowRef.current));

  if (!value) return <div></div>;
  const label = LibService.instance().getFieldLabel(props.pageConfig, props.field.name);

  return (
    <div>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography className={classes.heading}>{label}</Typography>
        </AccordionSummary>
        <AccordionDetails className={classes.details}>
          <QueryBuilder data={value} onChange={handleChange} />
          <div>
            <TextField
              label={'Count'}
              value={count}
              onChange={(e) => setCount(parseInt(e.target.value))}
              type="number"
              variant="outlined"
              size="small"
              style={{ width: 60, marginRight: 25 }}
            />
            <Button onClick={fillValues} variant="contained">
              Replicate
            </Button>
          </div>
          <div style={{ maxHeight: 600, overflow: 'auto' }}>
            <h3>Query:</h3>
            <pre style={queryStyle}>{dataRowRef.current.mongo}</pre>
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}

const groupStyle = { width: 100, display: 'inline-block', paddingRight: 5 };
const queryStyle = { backgroundColor: '#ccc', margin: '10px', padding: '10px' };
