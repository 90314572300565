// Auto generated file
import { PageConfig, PageField, PageGridField, Validators } from 'onka-react-admin-core';
import CC from '../../../../components/CustomComponents';
import emailApiEnums from '../../emailApiEnums';

export const pageConfig = new PageConfig({
  route: '/EmailApi/EmailHistorySearch',
  moduleKey: 'EmailApi',
  pageKey: 'EmailHistory',
  menu: 'EmailApi',
  menuOrder: 0,
  hideMenu: false,
  get: true,
  edit: false,
  new: false,
  delete: false,
  export: false,
  primaryKeys: ['id'],
  fields: [
    new PageField({
      displayInDetail: true,
      name: 'id',
      validators: [Validators.required, Validators.maxLength(50)]
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'templateId',
      validators: [Validators.required, Validators.maxLength(50)],
      reference: {
        route: 'EmailApi/EmailTemplateSearch',
        filterField: 'title',
        dataField: 'template',
      },
      createComponent: CC.ReferenceComponent,
      editComponent: CC.ReferenceComponent
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'fromEmail',
      validators: [Validators.required, Validators.maxLength(200)]
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'fromName',
      validators: [Validators.required, Validators.maxLength(200)]
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'toEmail',
      validators: [Validators.required, Validators.maxLength(200)]
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'toName',
      validators: [Validators.required, Validators.maxLength(200)]
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'date',
      validators: [Validators.required],
      createComponent: CC.DateComponent,
      editComponent: CC.DateComponent
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'subject',
      validators: [Validators.required, Validators.maxLength(8000)],
      fieldSize: 12,
      createComponent: CC.TextareaComponent,
      editComponent: CC.TextareaComponent
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'body',
      validators: [Validators.required, Validators.maxLength(8000)],
      fieldSize: 12,
      createComponent: CC.TextareaComponent,
      editComponent: CC.TextareaComponent
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'toCC',
      validators: [Validators.maxLength(8000)],
      createComponent: CC.TextareaComponent,
      editComponent: CC.TextareaComponent
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'toBCC',
      validators: [Validators.maxLength(8000)],
      createComponent: CC.TextareaComponent,
      editComponent: CC.TextareaComponent
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'emailStatus',
      enumName: 'EmailStatus',
      enum: emailApiEnums.EmailStatus,
      validators: [Validators.required, Validators.maxLength(50)],
      createComponent: CC.SelectComponent,
      editComponent: CC.SelectComponent
    }),
    new PageField({
      displayInDetail: true,
      displayInCreate: true,
      displayInEdit: true,
      name: 'lastError',
      validators: [Validators.maxLength(8000)],
      createComponent: CC.TextareaComponent,
      editComponent: CC.TextareaComponent
    })
  ],
  filterFields: [
    new PageField({
      name: 'templateId',
      filterComponent: CC.ReferenceComponent,
      reference: {
        route: 'EmailApi/EmailTemplateSearch',
        filterField: 'title',
        dataField: 'template',
        
      }
    }),
    new PageField({
      name: 'subject',
      filterComponent: CC.TextareaComponent
    }),
    new PageField({
      name: 'body',
      filterComponent: CC.TextareaComponent
    }),
    new PageField({
      name: 'date4Greater',
      filterComponent: CC.DateComponent
    }),
    new PageField({
      name: 'date5LessEq',
      filterComponent: CC.DateComponent
    }),
    new PageField({
      name: 'toName'
    }),
    new PageField({
      name: 'toEmail'
    })
  ],
  gridFields: [
    new PageGridField({
      name: 'templateId',
      reference: {
        route: 'EmailApi/EmailTemplateSearch',
        filterField: 'title',
        dataField: 'template',
      },
      dataType: 'string'
    }),
    new PageGridField({
      name: 'subject',
      dataType: 'string'
    }),
    new PageGridField({
      name: 'toEmail',
      dataType: 'string'
    }),
    new PageGridField({
      name: 'toName',
      dataType: 'string'
    }),
    new PageGridField({
      name: 'date',
      dataType: 'string'
    })
  ],
});

