import React from 'react';
import packageJson from '../../../package.json';

const env = process.env.REACT_APP_ADZYENV;
const api = process.env.REACT_APP_API_URL;
const cdn = process.env.REACT_APP_CDN_URL;

function About() {
  return (
    <div>
      <div>Adzy Env: {env}</div>
      <div>API URL: {api}</div>
      <div>Version: {packageJson.version}</div>
      <div>CDN: {cdn}</div>
    </div>
  );
}

export default About;
